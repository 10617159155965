import { message } from 'antd';

import { DURATION_TIMER } from '../../appConstants';
import {
  ACTIVATE_CUSTOMER,
  ACTIVATE_CUSTOMER_FAIL,
  ACTIVATE_CUSTOMER_SUCCESS,
  DEACTIVATE_CUSTOMER,
  DEACTIVATE_CUSTOMER_FAIL,
  DEACTIVATE_CUSTOMER_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_VOUCHERS,
  GET_CUSTOMER_VOUCHERS_FAIL,
  GET_CUSTOMER_VOUCHERS_SUCCESS,
  SEARCH_CUSTOMER,
  SEARCH_CUSTOMER_FAIL,
  SEARCH_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS
} from './constants';

export const getCustomer = payload => {
  return {
    type: GET_CUSTOMER,
    endpoint: `/v4/dash/customers/${payload.id}`,
    types: [GET_CUSTOMER, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
    notification: {
      loading: () => {
        message.loading('Loading form data...');
      },
      error: () => {
        message.error('Something Went Wrong!', DURATION_TIMER);
      }
    },
    params: payload
  };
};

export const getCustomerFail = payload => {
  return {
    type: GET_CUSTOMER_FAIL,
    payload
  };
};

export const getCustomerSuccess = payload => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload
  };
};

export const getCustomerVouchers = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS,
    payload
  };
};

export const getCustomerVouchersFail = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_FAIL,
    payload
  };
};

export const getCustomerVouchersSuccess = payload => {
  return {
    type: GET_CUSTOMER_VOUCHERS_SUCCESS,
    payload
  };
};

export const getCustomers = payload => {
  return {
    type: GET_CUSTOMERS,
    payload
  };
};

export const getCustomersFail = payload => {
  return {
    type: GET_CUSTOMERS_FAIL,
    payload
  };
};

export const getCustomersSuccess = payload => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload
  };
};

export const updateCustomer = payload => {
  return {
    type: UPDATE_CUSTOMER,
    payload
  };
};

export const updateCustomerSuccess = payload => {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    payload
  };
};
export const updateCustomerFail = payload => {
  return {
    type: UPDATE_CUSTOMER_FAIL,
    payload
  };
};

export const searchCustomer = payload => {
  return {
    type: SEARCH_CUSTOMER,
    payload
  };
};

export const searchCustomerSuccess = payload => {
  return {
    type: SEARCH_CUSTOMER_SUCCESS,
    payload
  };
};

export const searchCustomerFail = payload => {
  return {
    type: SEARCH_CUSTOMER_FAIL,
    payload
  };
};

export const activateCustomer = (payload, callback) => {
  return {
    type: ACTIVATE_CUSTOMER,
    endpoint: `/v4/dash/customers/${payload.id}/activate`,
    method: 'patch',
    types: [ACTIVATE_CUSTOMER, ACTIVATE_CUSTOMER_SUCCESS, ACTIVATE_CUSTOMER_FAIL],
    notification: {
      loading: () => message.loading('Activating customer ...'),
      success: () => message.success('Successfully Activated!'),
      error: () => message.error('Activation failed')
    },
    nextAction:
      payload.page && payload.page === 'list'
        ? { type: GET_CUSTOMERS }
        : {
            type: GET_CUSTOMER,
            types: [GET_CUSTOMERS, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
            payload
          },
    formdata: payload,
    callback
  };
};

export const activateCustomerSuccess = payload => {
  return {
    type: ACTIVATE_CUSTOMER_SUCCESS,
    payload
  };
};

export const activateCustomerFail = payload => {
  return {
    type: ACTIVATE_CUSTOMER_FAIL,
    payload
  };
};

export const deactivateCustomer = (payload, callBack) => {
  return {
    type: DEACTIVATE_CUSTOMER,
    endpoint: `/v4/dash/customers/${payload.id}/deactivate`,
    method: 'patch',
    types: [DEACTIVATE_CUSTOMER, DEACTIVATE_CUSTOMER_SUCCESS, DEACTIVATE_CUSTOMER_FAIL],
    params: { reason: payload.reason },
    notification: {
      loading: () => {
        message.loading('Deactivating customer...');
      },
      success: () => {
        message.success('Successfully deactivated!', DURATION_TIMER);
      },
      error: () => {
        message.error('Deactivation failed', DURATION_TIMER);
      }
    },
    nextAction:
      payload.page && payload.page === 'list'
        ? { type: GET_CUSTOMERS }
        : {
            type: GET_CUSTOMER,
            types: [GET_CUSTOMERS, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAIL],
            payload
          },
    formdata: payload,
    callBack
  };
};

export const deactivateCustomerSuccess = payload => {
  return {
    type: DEACTIVATE_CUSTOMER_SUCCESS,
    payload
  };
};

export const deactivateCustomerFail = payload => {
  return {
    type: DEACTIVATE_CUSTOMER_FAIL,
    payload
  };
};

export const issueRewards = (payload, history?) => {
  return {
    type: 'ISSUE_REWARDS',
    payload,
    history
  };
};

export const issueVoucherSuccess = payload => {
  return {
    type: 'ISSUE_REWARDS_SUCCESS',
    payload
  };
};
