import { Button } from 'antd';
import styled, { css } from 'styled-components';

const theme = {
  link: {
    fc: '#2665ee',
    bg: 'transparent',
    bc: 'transparent',
    bcd: 'transparent'
  },
  primary: {
    fc: 'white',
    bg: '#2665ee',
    bc: '#2665ee',
    bcd: '#cb4900'
  },
  danger: {
    fc: 'white',
    bg: '#f5222d',
    bc: '#fb7901',
    bcd: '#cb4900'
  },
  default: {
    bg: 'rgba(255, 255, 255, 0.5);',
    bc: '#2665ee',
    bcd: '#cb4900',
    fc: '#2665ee'
  },
  disabled: {
    bg: 'rgba(255, 255, 255, 0.5);',
    bc: '#2665ee',
    bcd: '#cb4900',
    fc: '#2665ee'
  },
  lime: {
    bg: '#9bcf00',
    fc: '#fff',
    bcd: '#9bcf00',
    bc: '#9bcf00'
  },
  draft: {
    bg: '#b9b9b9',
    fc: '#fff',
    bcd: '#b9b9b9',
    bc: '#b9b9b9'
  },
  active: {
    bg: '#9bcf00',
    fc: '#fff',
    bcd: '#9bcf00',
    bc: '#9bcf00'
  },
  pending: {
    bg: '#f5a623',
    fc: '#fff',
    bcd: '#f5a623',
    bc: '#f5a623'
  },
  initial: {
    bg: '#f5a623',
    fc: '#fff',
    bcd: '#f5a623',
    bc: '#f5a623'
  },
  approved: {
    bg: '#108ee9',
    fc: '#fff',
    bcd: '#108ee9',
    bc: '#108ee9'
  },
  ended: {
    bg: '#c23934',
    fc: '#fff',
    bcd: '#c23934',
    bc: '#c23934'
  },
  inactive: {
    bg: '#b9b9b9',
    fc: '#fff',
    bcd: '#b9b9b9',
    bc: '#b9b9b9'
  }
};

interface CustomButtonProps {
  margin?: string;
  nomargin?: boolean;
  width?: string;
  height?: string;
  isLowercase?: boolean;
  fullwidth?: boolean;
  boolean?: boolean;
  disableHover?: boolean;
  hasRightActionIcon?: boolean;
}

export const StyledButton = styled(Button)<CustomButtonProps>`
  &&& {
    margin: ${({ margin, nomargin }) => (nomargin ? '0' : margin ? margin : '0 4px')};
    color: ${props => theme[props.type].fc};
    border: ${({ type }) => (type === 'link' ? 'none' : `1px solid ${theme[type].bc}`)};
    background: ${props => theme[props.type].bg};
    width: ${({ width }) => width || ''};
    font-size: 14px;
    padding: 0.8em 2em;
    min-width: 79px;
    ${({ type }) =>
      type === 'link' &&
      css`
        box-shadow: none;
      `};
    font-weight: normal;
    ${({ height }) =>
      !height
        ? css`
            height: 44px;
          `
        : css`
            height: ${height}px;
          `};
    ${({ fullwidth }) =>
      fullwidth &&
      css`
        width: 100%;
      `}

    &:hover,
    &:focus,
    &:active {
      background-color: ${({ type }) =>
        type === 'primary' ? '#2665ee' : 'rgba(251, 121, 1, 0.16)'};
      color: ${({ type }) => (type === 'primary' ? 'white' : '#2665ee')};
    }

    &:disabled,
    [disabled] {
      opacity: 0.65;
    }
    /* &:first-child {
      margin-left: 0;
    } */
    &:last-child {
      margin-right: 0;
    }

    ${({ disableHover }) =>
      disableHover &&
      css`
        &:hover {
          cursor: default;
          background-color: ${
            /* @ts-ignore */
            ({ type }) => (type === 'primary' ? '#2665ee' : type === 'default' ? 'white' : '')
          };
          color: ${
            /* @ts-ignore */
            ({ type }) => (type === 'primary' ? 'white' : type === 'default' ? '#FB7901' : '')
          };
        }
      `}

    ${({ hasRightActionIcon }) =>
      hasRightActionIcon &&
      css`
        .anticon {
          cursor: pointer;
          margin-left: 10px;

          &:hover {
            opacity: 0.7;
          }
        }
      `}
  }
`;

export default StyledButton;
