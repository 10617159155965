import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';
import moment from 'moment';
import uuid from 'uuid/v4';

import placeholders from 'components/Phone/placeholders';
import { placeholder as StampPlaceholders } from 'containers/Campaign/StampCampaign/stamp_placeholders';
import { IAnyCampaign } from 'containers/Campaign/types';
import { campaignNormalizeData } from 'pages/campaign/helper';
import { getDeepKeys } from 'utils/helpers';

export const getStatic = (campaignType, gameType, displayProperties: object) => {
  const newStaticFields = {};

  //Get different static keys for games and stamps
  let toUpdate = [];
  if (campaignType === 'game') {
    toUpdate = [
      ...getDeepKeys(placeholders[gameType]['static']).filter(key => !key.includes('wedges['))
    ];
    // Exclude getting the deep keys for wedges but instead only the top level key
    if (gameType === 'spin_the_wheel') {
      toUpdate.push('wedges');
    }
  } else if (campaignType === 'stamp') {
    toUpdate = getDeepKeys(StampPlaceholders['static']);
  }

  toUpdate.forEach(key => {
    let useKey = key;
    let value = useKey?.split('.')?.reduce((o, i) => {
      if (!!o && o?.hasOwnProperty(i)) {
        return o[i];
      } else {
        return null;
      }
    }, displayProperties);

    if (!value) {
      if (useKey.includes('image_url')) {
        useKey = useKey.replace('image_url', 'file');
      } else if (useKey.includes('file') && !useKey.includes('filename')) {
        useKey = useKey.replace('file', 'image_url');
      }

      value = useKey.split('.').reduce((o, i) => {
        if (!!o && o.hasOwnProperty(i)) {
          return o[i];
        } else {
          return null;
        }
      }, displayProperties);
    }

    _set(newStaticFields, useKey, value);
  });

  return newStaticFields;
};

export const getTranslated = (gameType, displayProperties) => {
  const newTranslated = {};
  const toUpdate = getDeepKeys(placeholders[gameType]['translatable']);

  toUpdate.forEach(key => {
    let useKey = key;
    let value = useKey.split('.').reduce((o, i) => {
      if (!!o && o?.hasOwnProperty(i)) {
        return o[i];
      } else {
        return null;
      }
    }, displayProperties);

    _set(newTranslated, useKey, value);
  });

  return newTranslated;
};

export const initializeOutcomes = outcomeArray => {
  if (_isArray(outcomeArray)) {
    return outcomeArray.map(outcome => {
      const modified = { ...outcome };
      delete modified.campaign_module_id;

      return modified;
    });
  } else {
    return [];
  }
};

export const initializeMilestones = milestones => {
  return milestones.map(milestone => {
    const modded = { ...milestone };
    delete modded.id;

    return modded;
  });
};

export const initializeQuestTasks = (questTasks = []) => {
  return questTasks.map(task => {
    const stripped = { ...task };

    delete stripped.campaign_id;
    delete stripped.id;
    delete stripped.ordering;

    const rule = stripped?.rule || {};
    rule.state = 'draft';
    delete rule.id;
    delete rule.earn_type;
    delete rule.exclude_audience_ids;
    delete rule.include_audience_ids;

    stripped.rule = rule;

    return stripped;
  });
};

export const stripDataIds = (campaignData: IAnyCampaign) => {
  const stripped = { ...campaignData };
  delete stripped.id;
  delete stripped.end_date;
  stripped.name = `Copy of ${stripped.name}`;
  stripped.name_en = `Copy of ${stripped.name_en}`;

  //Remove voucher id to induce creation of new reward voucher reference
  if (!_isEmpty(stripped.outcomes)) {
    stripped.outcomes = initializeOutcomes(stripped.outcomes);
  }

  const campaignType = stripped.campaign_type;
  if (campaignType === 'progress') {
    stripped.milestones = initializeMilestones(stripped.milestones);
  }

  if (campaignType === 'quest') {
    stripped.quest_tasks = initializeQuestTasks(stripped.quest_tasks);
  }

  //Remove rule ID to induce creation of new but identical rule.
  if (!_isEmpty(stripped.rules)) {
    stripped.rules = stripped.rules.map(rule => {
      const moddedRule = { ...rule };
      delete moddedRule.id;

      return moddedRule;
    });
  }

  return stripped;
};

export const resetQuestionIds = (campaignData: IAnyCampaign) => {
  const clonedCampaign = { ...campaignData };

  if (clonedCampaign?.display_properties?.questions?.length > 0) {
    clonedCampaign.display_properties.questions.forEach(element => {
      element.id = uuid();
      if (element?.payload?.choices?.length > 0) {
        element.payload.choices.forEach(choice => {
          choice.answer_id = uuid();
        });
      }
    });
  }

  return clonedCampaign;
};

export const reinitializeCampaignData = campaignData => {
  const normalizedData = campaignNormalizeData(campaignData);
  const strippedCampaign = stripDataIds(normalizedData);
  const campaign = resetQuestionIds(strippedCampaign);

  return {
    timezone: 'Asia/Kuala_Lumpur',
    images: campaign.images,
    audience: campaign.audience,
    active_at: campaign.start_date,
    duration_ends_at: campaign.end_date,
    name_en: campaign.name,
    //Filters
    filters: campaign.filters,
    membership: campaign?.filters?.membership || null,
    // include_audience_ids:campaign.include_audience_ids,
    // exclude_audience_ids:campaign.exclude_audience_ids,
    //Filter Ends
    state: campaign.state,
    description_en: campaign.description,
    custom_fields: campaign.custom_fields ? campaign.custom_fields : [],
    ...campaign,
    begins_at: moment(new Date()).toISOString(),
    start_date: moment(new Date()).toISOString(),
    tags: campaign.tags ? campaign.tags.map(r => r.id) : [],
    tag_ids: campaign.tags ? campaign.tags.map(r => r.id) : [],
    categories: campaign.categories ? campaign.categories.map(r => r.id) : [],
    labels: campaign.labels ? campaign.labels.map(r => r.id) : [],
    terms_and_conditions_en: campaign.terms_and_conditions
  };
};

export const campaign_filter_handler = (obj, prop) => {
  let cam;
  if (prop in obj) {
    cam = obj[prop];
  } else {
    cam = null;
  }

  // since location filter is not stored in db
  // it is the combination of city and states
  if (cam && (Object.keys(cam).includes('city') || Object.keys(cam).includes('states'))) {
    cam.location = [];
  }

  // since location filter is not stored in db
  // it is the combination of city and states
  //Setup Personal audience filter on edit initialization
  if (
    cam &&
    (Object.keys(cam).includes('classification') ||
      Object.keys(cam).includes('product_type') ||
      Object.keys(cam).includes('preferred_language') ||
      Object.keys(cam).includes('segment') ||
      Object.keys(cam).includes('primary_cluster_comb') ||
      Object.keys(cam).includes('primary_cluster_state') ||
      Object.keys(cam).includes('hub_club') ||
      Object.keys(cam).includes('membership') ||
      Object.keys(cam).includes('user_type'))
  ) {
    cam.personal = [];
  }

  return cam;
};
